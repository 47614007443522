import { render, staticRenderFns } from "./PersonalVerification.vue?vue&type=template&id=21c9b72b&scoped=true"
import script from "./PersonalVerification.vue?vue&type=script&lang=js"
export * from "./PersonalVerification.vue?vue&type=script&lang=js"
import style0 from "./PersonalVerification.vue?vue&type=style&index=0&id=21c9b72b&lang=css"
import style1 from "../assets/cuprum.css?vue&type=style&index=1&lang=css&external"
import style2 from "fulfillment-cuprum-auth-widget/dist/fulfillment-cuprum-auth-widget.css?vue&type=style&index=2&lang=css&external"
import style3 from "../assets/style-personal-verification.css?vue&type=style&index=3&id=21c9b72b&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21c9b72b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/fulfillment-previred-widget/fulfillment-previred-widget/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('21c9b72b')) {
      api.createRecord('21c9b72b', component.options)
    } else {
      api.reload('21c9b72b', component.options)
    }
    module.hot.accept("./PersonalVerification.vue?vue&type=template&id=21c9b72b&scoped=true", function () {
      api.rerender('21c9b72b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/PersonalVerification.vue"
export default component.exports