var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "personal-verification" },
    [
      _vm._m(0),
      _c("div", { class: _vm.inputContainerClass }, [
        _c(
          "div",
          { staticClass: "action-container" },
          [
            _vm.isCICUE && !_vm.showGetCode && !_vm.haveMessage
              ? _c("FulfillmentAuthentication", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.sendingCode,
                      expression: "!sendingCode",
                    },
                  ],
                  staticStyle: { "text-align": "left" },
                  attrs: {
                    rut: _vm.clientRut,
                    jwt: _vm.authToken,
                    "channel-key": _vm.channelKey,
                    email: _vm.clientEmail,
                    "url-redirect": _vm.urlCICUERedirect,
                    method: _vm.authMethod,
                    "strict-name-validation": _vm.strictNameValidation,
                    "timeout-ci": _vm.timeoutCi,
                    "timeout-cue": _vm.timeoutCue,
                    "mode-ci-cue": "VALIDATE",
                  },
                })
              : _vm._e(),
            _vm.showGetCode && !_vm.showAuthMethod
              ? _c("div", [
                  _c("h3", { staticClass: "title-get-code" }, [
                    _vm._v("Tu Asesoría Remota"),
                  ]),
                  _c("p", { staticClass: "text-get-code" }, [
                    _vm._v(
                      " Ingresa el código de asesoría que ha facilitado tu ejecutivo. "
                    ),
                  ]),
                  _c("div", { staticClass: "form-get-code" }, [
                    _vm._m(1),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.codeField,
                          expression: "codeField",
                        },
                      ],
                      staticClass: "input input-get-code",
                      attrs: {
                        id: "code",
                        type: "text",
                        placeholder: " A1234",
                        required: "",
                      },
                      domProps: { value: _vm.codeField },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.sendCode.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.codeField = $event.target.value
                        },
                      },
                    }),
                    _c("div", { staticClass: "help-link" }, [
                      _c(
                        "a",
                        {
                          attrs: { href: "#/personal-verification" },
                          on: {
                            click: function ($event) {
                              _vm.showModal = true
                            },
                          },
                        },
                        [_vm._v("¿Qué es el código de asesoría?")]
                      ),
                    ]),
                    _vm.haveMessage
                      ? _c("div", { staticClass: "alert-container" }, [
                          _c("div", { staticClass: "alert-icon" }, [
                            _vm._v("!"),
                          ]),
                          _c("div", { staticClass: "alert-menssage" }, [
                            _vm._v(
                              " Tu código de asesoría no es correcto. Vuelve a intentarlo o solicita uno nuevo a tu asesor remoto. "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass: "fulfillment-button",
                        attrs: {
                          id: "codeSender",
                          disabled: _vm.disableButton,
                        },
                        on: { click: _vm.sendCode },
                      },
                      [_vm._v(" Iniciar Validación de Identidad ")]
                    ),
                  ]),
                  _c("div", { staticClass: "contact-executive" }, [
                    _vm._v(
                      " Si aún no dispones de un código de asesoría, solicítalo a tu ejecutivo. "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.showAuthMethod
              ? _c("div", [
                  _c("h3", { staticClass: "title-get-code" }, [
                    _vm._v("¿Cómo quieres validar tu identidad?"),
                  ]),
                  _c("div", { staticClass: "text-container" }, [
                    _c("span", { staticClass: "text-get-code" }, [
                      _vm._v(_vm._s(_vm.textGetCode)),
                    ]),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "fulfillment-button",
                      attrs: { id: "codeSender2", disabled: _vm.disableButton },
                      on: { click: _vm.createAuthMethod },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.isFacial
                              ? "Validar con reconocimiento facial"
                              : _vm.isCICUE
                              ? "Validar con Clave Única"
                              : "Validar con preguntas de seguridad"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "auth" }, [
              !_vm.isCICUE
                ? _c(
                    "div",
                    [
                      !_vm.showGetCode && !_vm.haveMessage
                        ? _c("FulfillmentAuthentication", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.sendingCode,
                                expression: "!sendingCode",
                              },
                            ],
                            attrs: {
                              rut: _vm.clientRut,
                              company: _vm.company,
                              method: _vm.authMethod,
                              "channel-key": _vm.authApiKey,
                              environment: _vm.environment,
                              color: _vm.color,
                              "button-enabled": true,
                              "button-label": "Validar cliente",
                              "dummy-mode": _vm.dummyMode,
                              "trace-id": _vm.traceId,
                              "kong-token": _vm.kongToken,
                              "skip-first-screen": true,
                              "kong-mode-j-w-t": true,
                              "light-mode": true,
                              "facial-timer": 3,
                            },
                            on: {
                              auth: _vm.onAuthentication,
                              goBack: function ($event) {
                                return _vm.$emit("goBack")
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm.sendingCode
              ? _c("div", { staticClass: "loader-container" }, [
                  _c(
                    "p",
                    [
                      _c("Spinner"),
                      _c("br"),
                      _vm._v(
                        "Estamos Consultado tus datos. Esto puede durar unos segundos. "
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.haveMessage && (_vm.showGenericError || _vm.authEnded)
              ? _c("div", { staticClass: "message-container" }, [
                  _c("img", {
                    attrs: { src: _vm.messageImageUrl, alt: "Error icon" },
                  }),
                  !_vm.showGenericError
                    ? _c("span", { staticClass: "message-title" }, [
                        _vm._v(_vm._s(_vm.messageTitle)),
                      ])
                    : _vm._e(),
                  !_vm.showGenericError
                    ? _c("span", { staticClass: "message-text" }, [
                        _vm._v(_vm._s(_vm.messageText)),
                      ])
                    : _vm._e(),
                  _vm.showGenericError
                    ? _c("span", { staticClass: "message-text" }, [
                        _c("b", [
                          _vm._v("No ha sido posible completar el flujo"),
                        ]),
                        _c("br"),
                        _vm._v(
                          " Contáctate con tu ejecutivo para volver a iniciar la validación. "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "bottom-banner" }),
      _vm._m(2),
      _vm.showModal
        ? _c(
            "modal",
            {
              on: {
                close: function ($event) {
                  _vm.showModal = false
                },
              },
            },
            [
              _c("transition", { attrs: { name: "modal" } }, [
                _c("div", { staticClass: "modal-mask" }, [
                  _c("div", { staticClass: "modal-wrapper" }, [
                    _c("div", { staticClass: "modal-container" }, [
                      _c(
                        "div",
                        { staticClass: "modal-header" },
                        [
                          _vm._t("header", function () {
                            return [_vm._v(" Código de Asesoría ")]
                          }),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "modal-body" },
                        [
                          _vm._t("body", function () {
                            return [
                              _vm._v(
                                " Es el código que tu ejecutivo te enviará y con el cual podrás ingresar a contestar las preguntas de seguridad que validarán tu identidad para realizar el traspaso de tus fondos. "
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "modal-footer" },
                        [
                          _vm._t("footer", function () {
                            return [
                              _c(
                                "button",
                                {
                                  staticClass: "modal-default-button",
                                  on: {
                                    click: function ($event) {
                                      _vm.showModal = false
                                    },
                                  },
                                },
                                [_vm._v(" Cerrar ")]
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "principal-banner" }, [
      _c("img", {
        staticClass: "cuprum-logo",
        attrs: {
          src: require("../assets/cuprum-logo-white.png"),
          alt: "Cuprum logo",
        },
      }),
      _c("div", { staticClass: "principal-banner banner-title" }, [
        _vm._v(" Tu traspaso remoto a AFP Cuprum sin salir de casa "),
        _c("strong", [_vm._v("#JuntosyConectados")]),
      ]),
      _c("p", { staticClass: "principal-banner banner-subtitle" }, [
        _vm._v(
          " Para continuar con el proceso de traspaso ingresado por tu ejecutivo, es necesario que valides tu identidad. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "label-get-code", attrs: { for: "code" } },
      [_c("span", [_vm._v("Código de Asesoría")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "footer" }, [
      _c("img", {
        staticClass: "logo-superintendencia",
        attrs: {
          src: require("../assets/logo-super.png"),
          alt: "logo superintendencia",
        },
      }),
      _c("p", { staticClass: "text-footer" }, [
        _vm._v(
          " Entrega tu opinión a la SUPERINTENDENCIA DE PENSIONES sobre la calidad de servicio de tu AFP, "
        ),
        _c(
          "a",
          {
            staticClass: "help-link",
            attrs: { href: " https://es.research.net/r/spensiones" },
          },
          [_vm._v(" Comienza Aquí ")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }